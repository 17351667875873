<template>
  <svg
    v-if="paths.length"
    ref="objectTrackingSvg"
    v-resize-observer="updatePolylinePoints"
    width="100%"
    height="100%"
    class="tracking-path-svg"
  >
    <polyline
      :points="polylinePoints"
      fill="none"
      stroke="red"
      stroke-width="3"
    />

    <g v-for="item in paths" :key="`point-${item.timestamp}`">
      <circle
        class="clickable-point"
        :class="{
          highlighted: isTimestampHighlighted(item.timestamp),
        }"
        :cx="`${item.center[0] * 100}%`"
        :cy="`${item.center[1] * 100}%`"
        r="7"
        fill="#dc2626"
        pointer-events="all"
        stroke="white"
        stroke-width="1"
        :aria-label="`Tracking point at ${$moment(item.timestamp).format(
          'LTS'
        )}`"
        role="button"
        @click="onPointPathClicked(item.timestamp)"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { BBox, DateType } from "@evercam/shared/types"
import { ResizeObserverDirective } from "@evercam/ui"

export default Vue.extend({
  name: "ObjectTrackingPath",
  directives: { ResizeObserverDirective },
  props: {
    paths: {
      type: Array as PropType<
        Array<{ timestamp: number; center: [number, number]; bbox: BBox }>
      >,
      required: true,
    },
    selectedTimestamp: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      polylinePoints: "",
    }
  },
  watch: {
    paths() {
      this.$nextTick(() => {
        this.updatePolylinePoints()
      })
    },
  },
  mounted() {
    this.updatePolylinePoints()
  },
  methods: {
    updatePolylinePoints() {
      if (
        !this.paths ||
        this.paths.length === 0 ||
        !this.$refs.objectTrackingSvg
      ) {
        this.polylinePoints = ""

        return
      }

      const svg = this.$refs.objectTrackingSvg as SVGElement
      const svgWidth = svg.clientWidth || 1000
      const svgHeight = svg.clientHeight || 600

      this.polylinePoints = this.paths
        .map((point) => {
          const x = point.center[0] * svgWidth
          const y = point.center[1] * svgHeight

          return `${x},${y}`
        })
        .join(" ")
    },
    isTimestampHighlighted(timestamp: DateType) {
      return timestamp === this.selectedTimestamp
    },
    onPointPathClicked(timestamp: number): void {
      this.$emit("point-clicked", timestamp)
    },
  },
})
</script>

<style scoped>
.tracking-path-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10001;
  pointer-events: none;
}

.tracking-path-svg .clickable-point {
  pointer-events: all;
  cursor: pointer;
  transition: r 0.2s ease;
}

.tracking-path-svg .highlighted {
  animation: clickable-point-highlight 2s infinite;
}

.tracking-path-svg .clickable-point:hover {
  r: 10;
  fill: #ef4444;
}

@keyframes clickable-point-highlight {
  25% {
    r: 7;
  }
  50% {
    r: 10;
  }
  100% {
    r: 7;
  }
}
</style>

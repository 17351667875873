import type { TimelineCountEvent, TimelineEvent } from "@evercam/ui"
import type {
  TimelineDateInterval,
  TimelineProviderRequestParams,
  CraneCount,
  CraneLabel,
  CameraExid,
  DetectionsPresenceByLabel,
} from "@evercam/shared/types"
import { TimelineDataProvider } from "./timelineDataProvider"
import { DetectionLabel } from "@evercam/shared/types"
import moment from "moment-timezone"
import { AiApi } from "@evercam/shared/api/aiApi"

export class TimelineObjectDetectionIntervalsProvider extends TimelineDataProvider {
  private static presenceIntervalsPromise: Promise<Array<CraneCount>> | null =
    null
  private static onIntervalsChange: (
    intervalsByLabel: DetectionsPresenceByLabel
  ) => void
  readonly cameraExid: CameraExid
  readonly labelFilterFn: (label: DetectionLabel) => boolean

  constructor(params: {
    timezone: string
    cameraExid: CameraExid
    labelFilterFn: (label: CraneLabel) => boolean
  }) {
    super(params.timezone)
    this.cameraExid = params.cameraExid
    this.labelFilterFn = params.labelFilterFn || ((_) => true)
  }

  static registerIntervalsChangeCallback(
    callback: (intervalsByLabel: DetectionsPresenceByLabel) => void
  ) {
    TimelineObjectDetectionIntervalsProvider.onIntervalsChange = callback
  }

  async fetchEvents(
    params: TimelineDateInterval
  ): Promise<Array<TimelineEvent>> {
    if (!TimelineObjectDetectionIntervalsProvider.presenceIntervalsPromise) {
      TimelineObjectDetectionIntervalsProvider.presenceIntervalsPromise =
        this.doFetchIntervals(params).finally(() => {
          TimelineObjectDetectionIntervalsProvider.presenceIntervalsPromise =
            null
        })
    }

    return TimelineObjectDetectionIntervalsProvider.presenceIntervalsPromise.then(
      (intervalsByLabel) => {
        if (TimelineObjectDetectionIntervalsProvider.onIntervalsChange) {
          TimelineObjectDetectionIntervalsProvider.onIntervalsChange(
            intervalsByLabel
          )
        }

        const entry = Object.entries(intervalsByLabel).find(
          ([label, _intervals]) => this.labelFilterFn(label)
        )

        if (!entry) {
          return []
        }

        const intervals = entry[1]

        return intervals.map(({ fromDate, toDate }) => {
          let startDate = this.formatTimestamp(fromDate)
          let endDate = this.formatTimestamp(toDate)

          if (params.precision) {
            startDate = moment(startDate).startOf(params.precision).format()
            endDate = moment(endDate).endOf(params.precision).format()
          }

          return {
            startDate,
            endDate,
          }
        })
      }
    )
  }

  async fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineCountEvent>> {
    return this.fetchEvents(params)
  }

  private async doFetchIntervals(
    params: TimelineProviderRequestParams
  ): Promise<DetectionsPresenceByLabel> {
    return await AiApi.detections.getDetectionsPresenceDateIntervals({
      cameraExid: this.cameraExid,
      fromDate: new Date(params.fromDate).toISOString(),
      toDate: new Date(params.toDate).toISOString(),
      precision: params.precision,
    })
  }
}

<template>
  <TimelinePlayer
    dark
    :project="project"
    :height="playerHeight"
    hide-camera-selector
    :token="accountStore.token"
    :with-player-progress-bar="false"
    :is-playing="isPlaying"
    :events-groups-config="timelineEventsGroupsConfig"
    :player-start="siteAnalyticsStore.snapshotsInterval.from"
    :player-end="siteAnalyticsStore.snapshotsInterval.to"
    :selected-camera="siteAnalyticsStore.selectedCamera"
    :selected-timestamp="siteAnalyticsStore.selectedTimestamp"
    :from-date="siteAnalyticsStore.timelineFromDate"
    :to-date="siteAnalyticsStore.timelineToDate"
    :e-timeline-props="eTimelineProps"
    :refresh-breakpoints="refreshBreakpoints"
    :precision-breakpoints="precisionBreakpoints"
    :chart-type-by-precision="chartTypeByPrecision"
    :with-controls="!showRoiOverlay"
    :with-zoom-slider="!showRoiOverlay"
    :pan="!showRoiOverlay"
    @precision-change="currentPrecision = $event"
    @update-playback="isPlaying = $event"
    @seek="siteAnalyticsStore.selectTimestamp"
    @timeline-interval-change="onTimelineIntervalChange"
    @timestamp-change="refreshBoundingBoxes"
  >
    <template #imageOverlay="{ image }">
      <div class="h-100 w-100">
        <div
          ref="container"
          class="w-100 h-100 e-top-0 position-absolute d-flex align-center justify-center"
        >
          <PolygonDrawOverlay
            v-if="showRoiOverlay"
            :roi="selectedRoi"
            @close-edit-mode="closePolygonDrawEditor"
            @update-polygon-points="updateRoiPolygonPoints"
          />
          <div
            v-else-if="!isSegmentsMode"
            class="position-relative w-100 h-100"
          >
            <ObjectTrackingPath
              v-if="selectedObject"
              :paths="selectedObject.paths"
              :selected-timestamp="currentTimestamp"
              @point-clicked="onPointPathClicked"
            />
            <BoundingBox
              v-for="(item, i) in boundingBoxes"
              :key="item.label + i"
              :label="item.label"
              :x-min="item.bbox[0]"
              :y-min="item.bbox[1]"
              :x-max="item.bbox[2]"
              :y-max="item.bbox[3]"
              :style="{ opacity: isHiddenLabel(item.label) ? 0 : 1 }"
              title="Click to view the path"
              @click="structureSelectedObject(item)"
              @destroy-object-path="destroyObjectPath(item)"
            />
          </div>
          <svg
            v-else-if="segmentMasks.length && image"
            class="masks-container h-100 w-100"
          >
            <SegmentPolygon
              v-for="(item, i) in segmentMasks"
              :key="item.label + i"
              :label="item.label"
              :points="item.mask"
              :image-width="image.width"
              :image-height="image.height"
            />
          </svg>
        </div>
      </div>
    </template>
    <template #top-left>
      <div
        v-if="!isCranesMode && !showRoiOverlay"
        class="site-analytics__labels d-flex flex-wrap mt-6"
      >
        <DetectionLabelChip
          v-for="label in playerOverlayLabels"
          :key="label"
          :label="label"
          dark
          :disabled="isHiddenLabel(label)"
          @mouseenter.native="onLabelMouseenter(label)"
          @mouseleave.native="onLabelMouseleave(label)"
          @click.native="onLabelClicked(label)"
        />
      </div>
    </template>

    <template #top-right>
      <div class="site-analytics__controls mt-1 mr-2">
        <div class="d-flex">
          <EToggleSwitch
            v-if="!showRoiOverlay"
            v-model="siteAnalyticsStore.selectedMode"
            :options="modeOptions"
            class="w-100 mr-2"
            size="sm"
            color="primary"
          />
          <RoiSelector
            :value="selectedRoi"
            :rois="rois"
            :is-fetching-rois="isFetchingRois"
            @select-roi="selectRoi"
            @fetch-rois="fetchRois"
          />
        </div>
        <v-checkbox
          v-show="isSegmentsMode && !showRoiOverlay"
          v-model="isDetailedMode"
          label="Breakdown by label"
          dense
          dark
          hide-details
          class="checkbox pt-0 e-bg-gray-900 e-rounded-md"
        />
      </div>
    </template>

    <template v-if="!isCranesMode" #eventTooltip="{ active, type }">
      <DetectionLabelChip v-if="active" :label="type" dark />
    </template>
  </TimelinePlayer>
</template>

<script lang="ts">
import Vue from "vue"
import TimelinePlayer from "@evercam/shared/components/timelinePlayer/TimelinePlayer.vue"
import DetectionLabelChip from "@evercam/shared/components/siteAnalytics/DetectionLabelChip.vue"
import PolygonDrawOverlay from "@/components/siteAnalytics/PolygonDrawOverlay"
import RoiSelector from "@/components/siteAnalytics/RoiSelector"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import {
  DetectionLabel,
  Project,
  RoiShapeType,
  RoiType,
  TimelinePlayerConfig,
  TimelinePrecision,
  SiteAnalyticsMode,
  DateType,
} from "@evercam/shared/types"
import {
  camelToKebabCase,
  debounce,
  stringToColor,
} from "@evercam/shared/utils"
import { TimelineCraneActivityProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineCraneActivityProvider"
import {
  DAY,
  HOUR,
  TimelineColors,
  TLPlayerDefaultPrecisionBreakpoints,
  TLPlayerDefaultRefreshBreakpoints,
} from "@evercam/shared/constants/timeline"
import BoundingBox from "@evercam/shared/components/BoundingBox"
import ObjectTrackingPath from "@evercam/shared/components/siteAnalytics/ObjectTrackingPath"
import SegmentPolygon from "@evercam/shared/components/SegmentPolygon"
import { TimelineObjectDetectionIntervalsProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineObjectDetectionProvider"
import { TimelineChartType } from "@evercam/ui"
import { FrequencyToSecondsMap } from "@/components/constants"
import { TimelineSegmentsIntervalsProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineSegmentsProvider"
import { PERMISSIONS } from "@/constants/permissions"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useBreadcrumbStore } from "~/stores/breadcrumb"
export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_ANALYTICS.VIEW],
  },
  name: "SiteAnalyticsPlayer",
  components: {
    BoundingBox,
    SegmentPolygon,
    TimelinePlayer,
    DetectionLabelChip,
    PolygonDrawOverlay,
    RoiSelector,
    ObjectTrackingPath,
  },
  async asyncData({ params }) {
    const cameraExid = params.camera_exid
    const siteAnalyticsStore = useSiteAnalyticsStore()

    if (!cameraExid) {
      return
    } else if (cameraExid !== siteAnalyticsStore.selectedCamera?.exid) {
      await siteAnalyticsStore.selectCamera(cameraExid)
    }

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Site Analytics",
        href: "/site-analytics",
        icon: "fa-chart-simple",
      },
      {
        name: `${siteAnalyticsStore.selectedCamera.name} (${siteAnalyticsStore.selectedCamera.exid})`,
        href: `/site-analytics/${siteAnalyticsStore.selectedCamera.exid}`,
        icon: "fa-camera",
      },
      {
        name: "Timeline",
        icon: "fa-clock-rotate-left",
      },
    ]
  },
  data() {
    return {
      isPlaying: false,
      isDetailedMode: false,
      currentPrecision: TimelinePrecision,
      boundingBoxes: [],
      segmentsIntervalsByLabel: {},
      segmentsProviderByLabel: {},
      refreshBreakpoints: TLPlayerDefaultRefreshBreakpoints.map((bp) => {
        if (bp.precision === TimelinePrecision.Events) {
          return {
            precision: TimelinePrecision.Minute,
            breakpoint: HOUR / 2,
          }
        }

        return bp
      }),
      precisionBreakpoints: [
        ...TLPlayerDefaultPrecisionBreakpoints.slice(0, -1),
        {
          precision: TimelinePrecision.Minute,
          breakpoint: DAY / 2,
        },
        {
          precision: TimelinePrecision.Minute,
          breakpoint: 0,
        },
      ],
      modeOptions: [
        { label: "Detections", value: SiteAnalyticsMode.Detections },
        { label: "Segments", value: SiteAnalyticsMode.Segments },
      ],
      highlightedLabel: null,
      hiddenLabels: [],
      playerOverlayLabels: [],
      SiteAnalyticsMode,
      showRoiOverlay: false,
      selectedRoi: null,
      isFetchingRois: false,
      rois: [],
      selectedObject: null,
      currentTimestamp: null,
      lastDestroyedTrackId: null,
    }
  },
  head() {
    return {
      title: `Site Analytics | ${
        useSiteAnalyticsStore().selectedCamera?.name || "Evercam"
      }`,
    }
  },
  computed: {
    ...mapStores(useSiteAnalyticsStore, useAccountStore),
    isCranesMode(): boolean {
      return this.siteAnalyticsStore.selectedMode === SiteAnalyticsMode.Cranes
    },
    isSegmentsMode(): boolean {
      return this.siteAnalyticsStore.selectedMode === SiteAnalyticsMode.Segments
    },
    playerHeight() {
      return window.innerHeight
    },
    project(): Project {
      return {
        startedAt: this.siteAnalyticsStore.selectedCamera?.createdAt,
        timezone: this.siteAnalyticsStore.selectedCamera?.timezone,
        cameras: [this.siteAnalyticsStore.selectedCamera],
      } as Project
    },
    timelineEventsGroupsConfig(): TimelinePlayerConfig {
      if (this.isCranesMode) {
        return this.craneActivityEventsGroups
      } else if (this.isSegmentsMode) {
        return this.segmentsIntervalsEventsGroups
      } else {
        return this.detectionsIntervalsEventsGroups
      }
    },
    craneActivityEventsGroups(): TimelinePlayerConfig {
      return ["Crane-A", "Crane-B", "Crane-C"].reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: this.getLabelColor(label),
            provider: new TimelineCraneActivityProvider({
              cameraExid: this.siteAnalyticsStore.selectedCameraExid,
              timezone: this.siteAnalyticsStore.selectedCamera
                .timezone as string,
              craneLabelFilterFn: (l) =>
                this.getCraneNameByTrackingId(Number(l)) === label,
            }),
          },
        }
      }, {})
    },
    detectionsIntervalsEventsGroups(): TimelinePlayerConfig {
      return Object.values(DetectionLabel).reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: this.getLabelColor(label),
            isHidden: this.isHiddenLabel(label),
            provider: new TimelineObjectDetectionIntervalsProvider({
              cameraExid: this.siteAnalyticsStore.selectedCameraExid,
              timezone: this.siteAnalyticsStore.selectedCamera
                .timezone as string,
              labelFilterFn: (l) =>
                [label, camelToKebabCase(label)].includes(l),
            }),
          },
        }
      }, {})
    },
    segmentsIntervalsEventsGroups(): TimelinePlayerConfig {
      if (!this.isDetailedMode) {
        return {
          segments: {
            label: "Available dates",
            color: TimelineColors.anpr,
            provider: new TimelineSegmentsIntervalsProvider({
              cameraExid: this.siteAnalyticsStore.selectedCameraExid,
              timezone: this.siteAnalyticsStore.selectedCamera
                .timezone as string,
              labelFilterFn: (_l) => true,
              groupedMode: true,
            }),
          },
        }
      }

      const labels = Object.keys(this.segmentsIntervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )

      return labels.reduce((acc, label) => {
        return {
          ...acc,
          [label]: {
            label,
            color: this.getLabelColor(label),
            provider: this.getOrCreateSegmentsProvider(label),
          },
        }
      }, {})
    },
    eTimelineProps(): Record<string, unknown> {
      if (this.isCranesMode) {
        return { chartMinHeight: 100 }
      }

      let height = 5
      let padding = 0

      if (this.isSegmentsMode) {
        height = this.isDetailedMode ? 1 : 10
        padding = this.isDetailedMode ? 0 : 30
      }

      return {
        barHeight: height,
        showLabels: false,
        minChartHeight: 50,
        barYPadding: padding,
      }
    },
    chartTypeByPrecision(): Record<TimelinePrecision, TimelineChartType> {
      return Object.values(TimelinePrecision).reduce((acc, precision) => {
        return {
          ...acc,
          [precision]: this.isCranesMode
            ? TimelineChartType.LineGraph
            : TimelineChartType.Bars,
        }
      }, {})
    },
    timePerSnapshot(): number {
      return (
        FrequencyToSecondsMap[
          this.siteAnalyticsStore.selectedCamera?.cloudRecordingFrequency
        ] * 1000
      )
    },
    segmentMasks(): { label: string; mask: number[][] }[] {
      return Object.entries(this.siteAnalyticsStore.segmentsByLabel).reduce(
        (acc, [label, segments]) => {
          return acc.concat(segments.map((segment) => ({ label, ...segment })))
        },
        []
      )
    },
  },
  watch: {
    highlightedLabel(label) {
      const eventsGroups = Array.from(
        document.querySelectorAll(".event-group")
      ) as HTMLElement[]
      if (!label) {
        eventsGroups.forEach((el) => {
          el.style.filter = "none"
        })
      } else {
        eventsGroups.forEach((el) => {
          el.style.filter = el.classList.contains(`event-group-${label}`)
            ? "opacity(1)"
            : "opacity(0.2)"
        })
      }
    },
    "siteAnalyticsStore.trackingsByLabel"() {
      this.refreshBoundingBoxes(this.siteAnalyticsStore.selectedTimestamp)
    },
    "siteAnalyticsStore.selectedMode": {
      immediate: true,
      handler() {
        this.siteAnalyticsStore.selectTimestamp(
          this.siteAnalyticsStore.selectedTimestamp
        )
      },
    },
    "siteAnalyticsStore.selectedCamera": {
      immediate: true,
      async handler() {
        TimelineObjectDetectionIntervalsProvider.registerIntervalsChangeCallback(
          this.updateAvailableDetectionsLabels
        )
        TimelineSegmentsIntervalsProvider.registerIntervalsChangeCallback(
          this.updateAvailableSegmentationLabels
        )
        await this.fetchInitialSegmentsIntervals()
      },
    },
    boundingBoxes: {
      handler(newBoxes) {
        if (
          this.selectedObject &&
          !newBoxes.some((box) => box.trackId === this.selectedObject.trackId)
        ) {
          this.selectedObject = null
        }
        if (this.lastDestroyedTrackId) {
          const reappearingBox = newBoxes.find(
            (box) => box.trackId === this.lastDestroyedTrackId
          )

          if (reappearingBox) {
            this.lastDestroyedTrackId = null
            this.structureSelectedObject(reappearingBox)
          }
        }
      },
      deep: true,
    },
  },
  async mounted() {
    TimelineObjectDetectionIntervalsProvider.registerIntervalsChangeCallback(
      this.updateAvailableDetectionsLabels
    )
    TimelineSegmentsIntervalsProvider.registerIntervalsChangeCallback(
      this.updateAvailableSegmentationLabels
    )
    await this.fetchInitialSegmentsIntervals()
  },
  methods: {
    onTimelineIntervalChange: debounce(function ({ fromDate, toDate }) {
      this.siteAnalyticsStore.timelineFromDate = fromDate
      this.siteAnalyticsStore.timelineToDate = toDate
    }, 500),
    findNearestDetections(timestamp) {
      let trackings = []
      if (this.isCranesMode) {
        trackings =
          this.siteAnalyticsStore.trackingsByLabel[DetectionLabel.TowerCrane]
      } else {
        trackings = Object.entries(
          this.siteAnalyticsStore.trackingsByLabel
        ).reduce((acc, [label, trackings]) => {
          return acc.concat(
            trackings.map((tracking) => ({ label, ...tracking }))
          )
        }, [])
      }

      if (!trackings?.length) {
        return []
      }
      const timestampMs = new Date(timestamp).getTime()
      let filteredDetections = []

      let minTimeDifference = this.timePerSnapshot
      let nearestDetections = []
      for (const item of trackings) {
        const labelName = this.isCranesMode
          ? this.getCraneNameByTrackingId(item.trackId)
          : item.label

        for (const detection of item.detections) {
          const timeDifference = Math.abs(
            new Date(detection.timestamp).getTime() - timestampMs
          )
          if (timeDifference <= minTimeDifference) {
            minTimeDifference = timeDifference
            nearestDetections.push({
              trackId: item.trackId,
              label: labelName,
              originalLabel: item.label,
              bbox: detection.bbox,
              color: this.getLabelColor(labelName),
              timeDifference,
            })
          }
        }
      }

      filteredDetections = nearestDetections.filter(
        ({ timeDifference }) => timeDifference === minTimeDifference
      )

      return filteredDetections
    },
    getCraneNameByTrackingId(trackId) {
      const craneName = {
        1: "Crane-A",
        0: "Crane-B",
        2: "Crane-C",
      }

      return craneName[trackId]
    },
    refreshBoundingBoxes(t) {
      this.currentTimestamp = t
      if (Object.keys(this.siteAnalyticsStore.trackingsByLabel).length === 0) {
        return
      }
      this.boundingBoxes = this.findNearestDetections(t)
    },
    onLabelMouseenter(label) {
      this.highlightedLabel = label
    },
    onLabelMouseleave(label) {
      this.$setTimeout(() => {
        if (this.highlightedLabel === label) {
          this.highlightedLabel = null
        }
      }, 200)
    },
    onLabelClicked(label) {
      this.hiddenLabels = this.isHiddenLabel(label)
        ? this.hiddenLabels.filter((item) => item !== label)
        : [...this.hiddenLabels, label]
    },
    isHiddenLabel(label) {
      return !!this.hiddenLabels?.includes(label)
    },
    getLabelColor(label) {
      return stringToColor(label, true)
    },
    updateAvailableSegmentationLabels: debounce(function (intervalsByLabel) {
      const newLabels = Object.keys(intervalsByLabel)
      const oldLabels = Object.keys(this.segmentsIntervalsByLabel)
      const isNewLabelsSet =
        newLabels.length !== oldLabels.length ||
        !newLabels.every((label) => oldLabels.includes(label))
      if (isNewLabelsSet) {
        this.segmentsIntervalsByLabel = intervalsByLabel
      }

      if (this.siteAnalyticsStore.selectedMode !== SiteAnalyticsMode.Segments) {
        return
      }

      this.playerOverlayLabels = Object.keys(intervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )
    }, 500),
    updateAvailableDetectionsLabels(intervalsByLabel) {
      if (
        this.siteAnalyticsStore.selectedMode !== SiteAnalyticsMode.Detections
      ) {
        return
      }

      this.playerOverlayLabels = Object.keys(intervalsByLabel).sort((a, b) =>
        a.length > b.length ? -1 : 1
      )
    },
    getOrCreateSegmentsProvider(label, groupedMode = false) {
      if (!this.segmentsProviderByLabel[label]) {
        this.segmentsProviderByLabel[label] =
          new TimelineSegmentsIntervalsProvider({
            cameraExid: this.siteAnalyticsStore.selectedCameraExid,
            timezone: this.siteAnalyticsStore.selectedCamera.timezone as string,
            labelFilterFn: (l) => l === label,
            fixedPrecision: TimelinePrecision.Hour,
            groupedMode,
          })
      }

      return this.segmentsProviderByLabel[label]
    },
    async fetchInitialSegmentsIntervals() {
      const provider = this.getOrCreateSegmentsProvider("stub")
      await provider.fetchEvents({
        fromDate: this.siteAnalyticsStore.timelineFromDate,
        toDate: this.siteAnalyticsStore.timelineToDate,
        precision: this.currentPrecision,
      })
    },
    async updateRoiPolygonPoints(coordinates) {
      try {
        await AiApi.roi.updateROI({
          updatedBy: this.accountStore.email,
          roi: {
            id: this.selectedRoi.id,
            shapes: [{ type: RoiShapeType.Polygon, coordinates }],
          },
        })
        this.fetchRois()
      } catch (error) {
        console.error(error)
      }
    },
    selectRoi(roi) {
      this.selectedRoi = roi
      this.showRoiOverlay = !!roi
    },
    async fetchRois() {
      try {
        this.isFetchingRois = true
        this.rois = await AiApi.roi.getROIs(
          this.siteAnalyticsStore.selectedCamera.projectExid,
          {
            camerasExid: [this.siteAnalyticsStore.selectedCamera.exid],
            isactive: true,
            roiType: RoiType.SiteAnalytics,
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.isFetchingRois = false
      }
    },
    closePolygonDrawEditor() {
      this.showRoiOverlay = false
      this.selectedRoi = null
    },
    structureSelectedObject(item) {
      if (this.selectedObject && this.selectedObject.trackId !== item.trackId) {
        this.selectedObject = null
      }

      const selectedObject = this.siteAnalyticsStore.trackingsByLabel[
        item.label
      ]?.find((tracking) => tracking.trackId === item.trackId)

      if (!selectedObject) {
        console.warn(`No tracking data found for trackId: ${item.trackId}`)

        return
      }

      const paths = []

      for (const detection of selectedObject.detections) {
        paths.push({
          center: [
            detection.bbox[0] + (detection.bbox[2] - detection.bbox[0]) / 2,
            detection.bbox[1] + (detection.bbox[3] - detection.bbox[1]) / 2,
          ],
          bbox: detection.bbox,
          timestamp: detection.timestamp,
        })
      }

      paths.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )

      this.selectedObject = {
        label: item.label,
        trackId: item.trackId,
        paths,
      }
    },
    onPointPathClicked(timestamp: DateType) {
      if (this.isTimestampHighlighted(timestamp)) {
        return
      }
      this.siteAnalyticsStore.selectTimestamp(timestamp)
    },
    isTimestampHighlighted(timestamp: DateType) {
      return timestamp === this.currentTimestamp
    },
    destroyObjectPath() {
      if (!this.selectedObject) {
        return
      }

      this.lastDestroyedTrackId = this.selectedObject.trackId
    },
  },
})
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";
@import "~@evercam/shared/styles/mixins";

.bounding-box {
  &__label {
    top: 0;
    left: 0.5rem;
  }
  &:not(.bounding-box--active) {
    transition: none !important;
  }
}
.site-analytics__labels {
  align-items: flex-start;
  align-content: baseline;
  width: 120px;
  overflow: auto;
  overflow-x: hidden;
  @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
  @include fading-scroll-overflow(
    $container-height: 100% !important,
    $mask-height-top: -32px
  );
  .detection-label {
    margin: 0.1em 0.2em;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

::v-deep .e-layout__top-left {
  height: 60% !important;
}

.event-group {
  transition: filter 0.1s;
}
</style>

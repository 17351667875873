<template>
  <div class="detection-label" :class="wrapperClasses">
    <div :style="labelStyles" class="detection-label__content">
      <slot v-bind="{ textColor, backgroundColor }">
        {{ formattedLabel }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import * as d3 from "d3"
import { DetectionLabel } from "@evercam/shared/types"
import { stringToColor, toTitleCase } from "@evercam/shared/utils.ts"

export default Vue.extend({
  name: "DetectionLabelChip",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    dark: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapperClasses(): Record<string, boolean> {
      return {
        "e-bg-gray-900": this.dark,
        "e-bg-gray-50": !this.dark,
      }
    },
    labelStyles(): Record<string, unknown> {
      return {
        background: this.disabled ? "#767676" : this.backgroundColor,
        color: this.disabled ? "#c5c5c5" : this.textColor,
      }
    },
    baseColor(): string {
      return stringToColor(this.label, true)
    },
    textColor(): string {
      return (
        this.dark
          ? d3.color(this.baseColor)?.brighter(1.5)
          : d3.color(this.baseColor)?.darker(2)
      )!.toString()
    },
    backgroundColor(): string {
      let color = d3.color(this.baseColor)
      color!.opacity = this.dark ? 0.2 : 0.5

      return color!.toString()
    },
    formattedLabel(): string {
      return toTitleCase(this.label)
    },
  },
})
</script>

<style scoped lang="scss">
.detection-label {
  display: inline-block;
  border-radius: 0.3em;
  user-select: none;
  word-break: break-word;
  &__content {
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 500;
    line-height: 1.4em;
    padding: 0 0.6em;
    border-radius: 0.3em;
  }
}
</style>

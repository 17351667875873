import { render, staticRenderFns } from "./SegmentPolygon.vue?vue&type=template&id=40135ebe&scoped=true"
import script from "./SegmentPolygon.vue?vue&type=script&lang=ts"
export * from "./SegmentPolygon.vue?vue&type=script&lang=ts"
import style0 from "./SegmentPolygon.vue?vue&type=style&index=0&id=40135ebe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40135ebe",
  null
  
)

export default component.exports